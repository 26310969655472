import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout className="vh-100 flex flex-column items-center justify-center">
    <h1>404</h1>
    <p>Selv de bedste websites har ikke alle sider.</p>
    <Link to="/" className="btn ratm-maroon ">
      <span role='img' aria-label='Back'>↩️</span>
      Forsiden
    </Link>
  </Layout>
);

export default NotFoundPage;
